<template>
  <div
    ref="dropdownRef"
    class="dropdown"
    :class="{ dropdown_open: isOpen, dropdown_disabled: disabled }"
    @contextmenu.prevent="handleContextMenu"
    @pointerleave="
      () => {
        if (props.hoverOpen) isOpen = false;
      }
    "
  >
    <div
      ref="toggle"
      class="dropdown__toggle"
      :class="toggleClass"
      @click.prevent="handleClick"
      @pointerenter="
        () => {
          if (props.hoverOpen) isOpen = true;
        }
      "
      @contextmenu.prevent.stop="handleContextMenu"
    >
      <slot />
    </div>
    <div
      ref="body"
      class="dropdown__popper-body"
      @click="toggle"
    >
      <div
        :class="bodyClass"
        :style="{ ...bodyStyle, display: isOpen ? 'flex' : 'none' }"
        class="dropdown-body"
      >
        <slot
          name="body"
          :toggle="toggle"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onUnmounted, ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    width?: string;
    padding?: string;
    overflow?: string;
    hoverOpen?: boolean;
    contextMenu?: boolean;
    disabled?: boolean;
    caret?: boolean;
    bodyClass?: string;
    placement?: string;
    open?: boolean;
  }>(),
  {
    width: 'auto',
    padding: '0',
    overflow: 'auto',
    hoverOpen: false,
    contextMenu: false,
    disabled: false,
    caret: false,
    bodyClass: 'dropdown__body',
    placement: 'bottom-start',
    open: false,
  }
);

const emit = defineEmits(['toggle', 'close', 'open']);

// Local state variables
const isOpen = ref(props.open);
const dropdownRef = ref<HTMLElement | null>(null);

// Computed properties for toggle class and body style
const toggleClass = computed(() => (props.caret ? 'dropdown__toggle_caret' : null));
const bodyStyle = computed(() => ({
  width: props.width,
  padding: props.padding,
  overflow: props.overflow,
}));

// Toggle function to handle opening/closing the dropdown
const toggle = (event?: Event | null, val?: boolean) => {
  isOpen.value = val !== undefined ? val : !isOpen.value;
  emit('toggle', isOpen.value, event);

  if (!isOpen.value) {
    emit('close');
  } else {
    emit('open');
  }

  // Add or remove click listener based on open state
  nextTick(() => {
    if (isOpen.value) {
      document.body.addEventListener('click', handleBodyClick);
    } else {
      document.body.removeEventListener('click', handleBodyClick);
    }
  });
};

// Handle clicks outside the dropdown
const handleBodyClick = (event: MouseEvent) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target as Node)) {
    toggle(null, false);
  }
};

// Handle dropdown click event
const handleClick = () => {
  if (!props.disabled && !props.contextMenu) {
    toggle();
  }
};

// Handle context menu event
const handleContextMenu = () => {
  if (!props.disabled && props.contextMenu) {
    toggle();
  }
};

// Watch for changes to the `open` prop and sync with the internal `isOpen` value
watch(
  () => props.open,
  (newVal) => {
    isOpen.value = newVal;
  }
);

onUnmounted(() => {
  document.body.removeEventListener('click', handleBodyClick);
});
</script>

<style lang="scss">
.dropdown {
  z-index: 101;
  display: inline-block;
  position: relative;
  text-align: left;
}

.dropdown__toggle {
  cursor: pointer;
}

.dropdown__toggle_caret {
  &:after {
    display: inline-block;
    content: '\e901';
    font-family: icomoon;
    transition: transform 0.2s ease;
    margin-left: 5px;
    font-size: 5px;
    color: $foreground-gray;
    vertical-align: middle;
  }
}

.dropdown_open {
  .dropdown__toggle_caret:after {
    transform: rotate(180deg);
  }
}

.dropdown_disabled {
  cursor: auto;
}

.dropdown__body {
  font-size: 14px;
  //max-width: 100%;
  flex-direction: column;
  background: white;
  position: absolute;
  right: 0;
  margin-top: 16px;
  overflow: hidden;
  max-width: calc(100vw - 60px);
  border-radius: 10px;
  box-shadow: $new-shadow;
}

.dropdown__body > ul {
  padding: 0;
  margin: 0;
  list-style: none;

  [role='button'] {
    padding: 12px 15px;
    line-height: 1.2;
    display: block;
    color: $foreground-contrast !important;

    &:hover {
      background: #f2f2f2;
      text-decoration: none;
    }
  }

  &.small {
    font-size: 14px;

    [role='button'] {
      padding: 6px 15px;
    }
  }
}

.dropdown__popper-body {
  z-index: 101;
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition:
    opacity 0.1s,
    transform 0.1s;
  pointer-events: none;
}

.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}

.dropdown__divider {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
}
</style>
