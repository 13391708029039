import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.client) {
    (function (window: Window, document: Document, counterName: string) {
      // Initialize the counter array if it doesn't exist
      (window[counterName] = window[counterName] || []).push(() => {
        const options = {
          project: 7736365,
          trackHashes: true,
          user_id: null,
        };
        try {
          window.top100Counter = new top100(options);
        } catch (error) {
          console.error('Error initializing top100Counter:', error);
        }
      });

      // Create and insert the script element
      const firstScript = document.getElementsByTagName('script')[0];
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.async = true;
      scriptElement.src = (document.location.protocol === 'https:' ? 'https:' : 'http:') + '//st.top100.ru/top100/top100.js';

      // Function to insert the script into the document
      const insertScript = () => {
        firstScript.parentNode?.insertBefore(scriptElement, firstScript);
      };

      // Check for Opera browser and add event listener if necessary
      if (window.opera === '[object Opera]') {
        document.addEventListener('DOMContentLoaded', insertScript, false);
      } else {
        insertScript();
      }
    })(window, document, '_top100q');
  }
});
