<template>
  <NuxtLayout>
    <NuxtLoadingIndicator
      color="#C4BEFF"
      :height="10"
    />
    <NuxtPage />
    <img
      src="//counter.rambler.ru/top100.cnt?pid=7736365"
      alt="Топ-100"
    />
  </NuxtLayout>
</template>

<script setup lang="ts"></script>
<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: all 100ms;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
