<script setup lang="ts">
import { DrawerContent, DrawerOverlay, DrawerPortal, DrawerRoot } from 'vaul-vue';

type Props = {
  open: boolean;
  title?: string;
};

const props = defineProps<Props>();
const emit = defineEmits(['close']);

const onUpdate = (isOpen: boolean) => {
  if (!isOpen) emit('close');
};
</script>

<template>
  <client-only>
    <Teleport to="body">
      <DrawerRoot
        :open="props.open"
        @update:open="onUpdate"
      >
        <DrawerPortal to="body">
          <div
            v-bind="$attrs"
            style="width: 100%"
          >
            <DrawerOverlay class="drawer-overlay" />
            <DrawerContent class="drawer-content">
              <div class="drawer">
                <div class="drawer-handle" />
                <h4 class="modal-heading">{{ props.title }}</h4>
                <slot />
              </div>
            </DrawerContent>
          </div>
        </DrawerPortal>
      </DrawerRoot>
    </Teleport>
  </client-only>
</template>

<style scoped lang="scss">
.drawer-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.drawer-content {
  position: fixed;

  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-radius: 32px 32px 0 0;
  z-index: 1001;

  .drawer-handle {
    background: $background-button-secondary-hover;
    border-radius: 16px;
    width: 52px;
    height: 6px;
    margin: 0 auto;
  }

  .drawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px 52px 24px;
    max-height: 99dvh;

    .modal-heading {
      margin: 8px auto 24px auto;
    }
  }
}
</style>
